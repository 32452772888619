<template>
  <v-card flat class="rounded-t-0" :loading="loading" :to="link">
    <v-alert
      dense
      :prominent="!$vuetify.breakpoint.xs"
      class="ma-0 rounded-t-0 text-body-2"
      type="error"
      text
    >
      Bestätige die Wahl deiner myGymer-Angebote bis am
      <DateValue :value="choice.endDate" long /> um {{ choice.endTime }}.
    </v-alert>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import DateValue from "common/components/DateValue.vue";

export default defineComponent({
  components: { DateValue },
  props: ["link"],
  data() {
    return {
      loading: false,
      choice: {},
    };
  },
  computed: {},
  methods: {
    async fetchData() {
      this.loading = true;
      this.choice = await this.apiList({ resource: "optional/choice" });

      if (this.choice.enabled && !this.choice.hasConfirmed) {
        this.$emit("loaded");
      }
      this.loading = false;
    },
  },
  async created() {
    this.fetchData();
  },
});
</script>
